import React from "react";

const SummaryForm = ({ formData, onChangeValue }) => {
  return (
    <div>
      <h5 className='mt-3 mb-2 d-inline-block'>Summary</h5>
      <p style={{ fontSize: 14 }}>
        Summarize your resume by focusing on your career experience and key
        achievements
      </p>

      <div className='mb-4'>
        <textarea
          className='form-control'
          rows='8'
          value={formData?.details}
          onChange={(e) => onChangeValue(e, "details")}
          placeholder='Briefly describe your career and achievements here.'
        ></textarea>
      </div>
    </div>
  );
};

export default SummaryForm;
