import React from "react";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import QualificationJourney from "./QualificationJourney";
import ExperienceJourney from "./ExperienceJourney";
import CreateResumeBox from "./CreateResumeBox";

const CandidateOverView = () => {
  const { profile } = useSelector((state) => state.customer);

  const additional_fields = profile?.additional_fields
    ? JSON.parse(profile?.additional_fields)
    : {};

  return (
    <>
      <div>
        <h5 className='fs-18 fw-bold'>About</h5>
        <p className='text-muted mt-4'>
          {additional_fields?.details
            ? parse(additional_fields?.details?.replaceAll("\n", "<br/>") ?? "")
            : "No details available."}
          {}
        </p>
      </div>
      <div className='candidate-education-details mt-4'>
        <h6 className='fs-18 fw-bold mb-0'>Qualifications</h6>

        <QualificationJourney />
      </div>

      <div className='candidate-education-details mt-4'>
        <h6 className='fs-18 fw-bold mb-0'>Experiences</h6>

        <ExperienceJourney />
      </div>

      {profile?.customer_type === 2 && <CreateResumeBox />}
    </>
  );
};

export default CandidateOverView;
