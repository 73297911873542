import React from "react";
import classes from "./styles.module.css";

const sections = [
  { id: "section1", label: "Basic Info" },
  { id: "section2", label: "Work Experience" },
  { id: "section3", label: "Qualifications" },
  // { id: "section4", label: "Projects" },
  { id: "section4", label: "Skills" },
  { id: "section5", label: "Summary" },
];

const SectionSidebar = ({ setActiveSection, activeSection }) => {
  return (
    <div className={classes.tabContainer}>
      {sections?.map((section) => (
        <button
          key={section.id}
          className={`${classes.tab} ${activeSection === section.id ? classes.activeTab : ""}`}
          onClick={() => setActiveSection(section.id)}
        >
          {section.label}
        </button>
      ))}
    </div>
  );
};

export default SectionSidebar;
