import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import ResumeImg from "../../../../assets/images/dummy-resume.png";

const CreateResumeBox = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className='resume-builder-section'>
        <div className='dummy-resume'>
          <img src={ResumeImg} alt='' />
        </div>
        <div>
          <h6>Create and Customize Your Professional Resume</h6>

          <p className='mb-1' style={{ fontSize: 13 }}>
            Effortlessly build a professional resume with our Resume Builder.
            {/* Import your profile details, tweak them as needed, and download your
        customized resume in minutes. */}
          </p>
        </div>

        <Button
          color='primary'
          outline
          size='sm'
          type='button'
          onClick={() => navigate("/resume-builder")}
        >
          Create resume
        </Button>
      </div>
    </div>
  );
};

export default CreateResumeBox;
