import { UPDATE_RESUME_FORM_SUCCESS, UPDATE_RESUME_FORM_FAILURE } from '../constants/actionTypes';

const INITIAL_STATE = {
  formData: {},
};

export default function resumeBuilder(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_RESUME_FORM_SUCCESS:
      return { ...state, formData: action.payload.data };
    case UPDATE_RESUME_FORM_FAILURE:
      return { ...state, formData: {} };
    default:
      return state;
  }
}
