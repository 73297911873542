import React, { useState } from "react";
import { Button } from "reactstrap";
import QualificationModal from "../../Account/Candidate/MyProfile/QualificationModal";
import QualificationJourney from "../../Account/Candidate/MyProfile/QualificationJourney";
import { useSelector } from "react-redux";

const QualificationForm = () => {
  const { qualifications } = useSelector((state) => state.customer);

  // Qualification states
  const [openQualificationModal, setOpenQualificationModal] = useState(false);
  const [qualificationModalType, setQualificationModalType] = useState("add");
  const [qualificationData, setQualificationData] = useState(null);
  // Qualification modal handle
  const handleOpenQualificationModal = (type, data = null) => {
    setOpenQualificationModal(true);
    setQualificationModalType(type);
    setQualificationData(data);
  };

  return (
    <div style={{ minHeight: 300 }}>
      <h5 className='mt-3 mb-2 d-inline-block'>Qualifications</h5>

      {qualifications?.length > 0 ? (
        <div>
          <p style={{ fontSize: 14 }}>
            Highlight your academic and professional achievements effectively.
          </p>

          <QualificationJourney
            handleOpenQualificationModal={handleOpenQualificationModal}
            componentType='update'
          />
        </div>
      ) : (
        <p className='mt-3'>
          Summarize your qualifications by starting with your most recent
          achievements or education and then list earlier academic and
          professional milestones.
        </p>
      )}

      <Button
        color='primary'
        onClick={() => handleOpenQualificationModal("add")}
        outline
        size='sm'
        className='mt-3 mb-3'
      >
        Add Qualification
      </Button>

      {openQualificationModal && (
        <QualificationModal
          openQualificationModal={openQualificationModal}
          setOpenQualificationModal={setOpenQualificationModal}
          modalType={qualificationModalType}
          qualificationData={qualificationData}
        />
      )}
    </div>
  );
};

export default QualificationForm;
