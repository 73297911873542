import React from "react";
import { useSelector } from "react-redux";
import { getFirstChar, renderMonth } from "../../../../helpers/common";

export const getStartData = (item) => {
  const date =
    renderMonth(item?.additional_fields?.start_month) +
    " " +
    item?.additional_fields?.start_year;
  return date;
};

export const getEndData = (item) => {
  const date =
    renderMonth(item?.additional_fields?.end_month) +
    " " +
    item?.additional_fields?.end_year;

  return item?.additional_fields?.is_currently_working === "Yes"
    ? "Present"
    : date;
};



const ExperienceJourney = ({
  handleOpenExperienceModal,
  componentType = "overview",
}) => {
  const { experiences } = useSelector((state) => state.customer);


  return (
    <>
      {experiences && experiences?.length > 0 ? (
        experiences?.map((item) => {
          return (
            <div
              key={item?.id}
              className="candidate-education-content mt-4 d-flex"
            >
              <div className="circle flex-shrink-0 bg-soft-primary">
                {getFirstChar(item?.additional_fields?.position)}
              </div>
              <div className="ms-4">
                <div className="d-flex">
                  <h6 className="fs-16 mb-1 me-3 text-capitalize">
                    {item?.additional_fields?.position}
                  </h6>

                  {componentType === "update" ? (
                    <div
                      className="cursor-pointer"
                      onClick={() => handleOpenExperienceModal("update", item)}
                    >
                      <i className="uil uil-edit"></i>
                    </div>
                  ) : null}
                </div>

                <p className="mb-2 text-muted text-capitalize">
                  {item?.additional_fields?.company_name} (
                  {getStartData(item) + " - " + getEndData(item)})
                </p>
                <p className="text-muted">{item?.additional_fields?.details}</p>
              </div>
            </div>
          );
        })
      ) : (
        <>
          <p className="mt-4 mb-5">No work experiences have been added.</p>
        </>
      )}
    </>
  );
};

export default ExperienceJourney;
