import React, { useEffect, useState } from "react";
import "./resume.css";
import { Col, Row } from "reactstrap";
import ResumeBuilderForm from "./ResumeBuilderForm";
import classes from "./styles.module.css";
import SectionSidebar from "./SectionSidebar";

const ResumeBuilder = () => {
  const [activeSection, setActiveSection] = useState("section1");

  useEffect(() => {
    window.scrollTo({ top: 50, behavior: "smooth" });
  }, []);

  return (
    <div className={`custom-container container-fluid ${classes.mainSec}`}>
      <div className='main-resume-container'>
        <h3 className='text-center mb-5'>Resume Builder</h3>

        <Row>
          <Col lg={3}>
            <SectionSidebar
              setActiveSection={setActiveSection}
              activeSection={activeSection}
            />
          </Col>

          <Col lg={9}>
            <ResumeBuilderForm
              activeSection={activeSection}
              setActiveSection={setActiveSection}
            />
          </Col>
        </Row>

        {/* <Row>
          <Col lg={7} className="mt-5">
            <ResumeBuilderForm />
          </Col>

          <Col lg={5}>
            <ResumePreview />
          </Col>
        </Row> */}
      </div>
    </div>
  );
};

export default ResumeBuilder;
