import React, { useState } from "react";
import { Button } from "reactstrap";
import ExperienceJourney from "../../Account/Candidate/MyProfile/ExperienceJourney";
import { useSelector } from "react-redux";
import ExperienceModal from "../../Account/Candidate/MyProfile/ExperienceModal";

const WorkExperienceForm = () => {
  const { experiences } = useSelector((state) => state.customer);

  // Experience states
  const [openExperienceModal, setOpenExperienceModal] = useState(false);
  const [experienceModalType, setExperienceModalType] = useState("add");
  const [experienceData, setExperienceData] = useState(null);

  // Experience modal handle
  const handleOpenExperienceModal = (type, data = null) => {
    setOpenExperienceModal(true);
    setExperienceModalType(type);
    setExperienceData(data);
  };
  return (
    <div style={{ minHeight: 300 }}>
      <h5 className='mt-3 mb-2 d-inline-block'>Work Experience</h5>

      {experiences?.length > 0 ? (
        <div>
          <p style={{ fontSize: 14 }}>
            Details and updates about past job roles and responsibilities.
          </p>
          <ExperienceJourney
            handleOpenExperienceModal={handleOpenExperienceModal}
            componentType='update'
          />
        </div>
      ) : (
        <p className='mt-3'>
          Please provide a detailed list of all the companies where you have
          been employed, beginning with your current or most recent position and
          moving backward through your career history.
        </p>
      )}

      <Button
        color='primary'
        onClick={() => handleOpenExperienceModal("add")}
        outline
        size='sm'
        className='mt-3 mb-3'
      >
        Add Work Experience
      </Button>

      {openExperienceModal && (
        <ExperienceModal
          openExperienceModal={openExperienceModal}
          setOpenExperienceModal={setOpenExperienceModal}
          modalType={experienceModalType}
          experienceData={experienceData}
        />
      )}
    </div>
  );
};

export default WorkExperienceForm;
