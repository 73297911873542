import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateResumeForm } from "../../../actions/resumeBuilder";
import ResumePreview from "../ResumePreview";
import BasicInfoForm from "./BasicInfoForm";
import QualificationForm from "./QualificationForm";
import SkillsForm from "./SkillsForm";
import SummaryForm from "./SummaryForm";
import WorkExperienceForm from "./WorkExperienceForm";

const ResumeBuilderForm = ({ activeSection, setActiveSection }) => {
  const { profile } = useSelector((state) => state.customer);

  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});

  useEffect(() => {
    const additional_fields = profile?.additional_fields
      ? JSON.parse(profile?.additional_fields)
      : {};

    const initial_state = {
      name: profile?.name || "",
      username: profile?.username || "",
      email: profile?.email || "",
      mobile_number: profile?.mobile_number || "",
      details: additional_fields?.details || "",
      languages_known: additional_fields?.languages_known || "",
      location: additional_fields?.location || "",
      designation: additional_fields?.designation || "",
      current_salary_per_month:
        additional_fields?.current_salary_per_month || "",
      expected_salary_per_month:
        additional_fields?.expected_salary_per_month || "",
      skills: additional_fields?.skills || "",
      facebook_url: additional_fields?.facebook_url || "",
      twitter_url: additional_fields?.twitter_url || "",
      linkedin_url: additional_fields?.linkedin_url || "",
      whatsapp_number: additional_fields?.whatsapp_number || "",
      profile_picture: additional_fields?.profile_picture || "",
      resume: additional_fields?.resume || "",
      resume_size: additional_fields?.resume_size || "",
      cover_letter: additional_fields?.cover_letter || "",
      cover_letter_size: additional_fields?.cover_letter_size || "",
      hobbies: additional_fields?.hobbies || "",
      permanent_address: additional_fields?.permanent_address || "",
      present_address: additional_fields?.present_address || "",
      total_work_experience: additional_fields?.total_work_experience || "",
      job_type: additional_fields?.job_type || "",
      state: additional_fields?.state || "",
      same_as_permanent_address:
        additional_fields?.same_as_permanent_address || "",
    };

    setFormData(initial_state);
  }, [profile]);

  const onChangeValue = (e, value) => {
    setFormData({ ...formData, [value]: e.target.value });
  };

  const onInputBlur = () => {
    dispatch(updateResumeForm(formData));
  };

  useEffect(() => {
    dispatch(updateResumeForm(formData));
  }, [dispatch, formData]);


  const formComponents = {
    section1: BasicInfoForm,
    section2: WorkExperienceForm,
    section3: QualificationForm,
    // section4: ProjectsForm,
    section4: SkillsForm,
    section5: SummaryForm,
  };

  const lastSection =
    Object.keys(formComponents)[Object.keys(formComponents)?.length - 1];
  const isLastSection = lastSection === activeSection;

  const renderCurrentForm = () => {
    const FormComponent = formComponents[activeSection] || BasicInfoForm;

    return (
      <FormComponent
        formData={formData}
        onChangeValue={onChangeValue}
        onInputBlur={onInputBlur}
        setFormData={setFormData}
      />
    );
  };

  const handleNext = () => {
    try {
      const indexStr = activeSection?.substring(
        activeSection.length - 1,
        activeSection.length
      );
      const index = parseInt(indexStr, 10);

      if (index < 6) {
        setActiveSection(`section${index + 1}`);
      }

      window.scrollTo({ top: 50, behavior: "smooth" });
    } catch (error) {
      console.log("error while clicking next::", error);
    }
  };

  return (
    <div id='profile-form'>
        {renderCurrentForm()}

        <div className='text-end mt-2 d-flex justify-content-end gap-2'>
          <button
            type='button'
            className='btn btn-danger'
            onClick={() => setOpenPreviewModal(true)}
          >
            Preview & Save
          </button>

          {!isLastSection && (
            <button
              type='button'
              className='btn btn-primary'
              onClick={handleNext}
            >
              Next
            </button>
          )}
        </div>

      <ResumePreview
        openPreviewModal={openPreviewModal}
        setOpenPreviewModal={setOpenPreviewModal}
      />
    </div>
  );
};

export default ResumeBuilderForm;
