import React from "react";
import { dataUrl, humanReadableSize } from "../../../../helpers/common";

const UploadedFile = ({ name, resumeSize }) => {
  const resume_url = `${dataUrl}/customers/${name}`;

  return (
    <div className='profile-document-list d-flex align-items-center mb-3'>
      <div className='doc-icon flex-shrink-0'>
        <i className='uil uil-file'></i>
      </div>
      <div className='ms-3'>
        <h6 className='fs-14 mb-0'>{name}</h6>
        {resumeSize && (
          <p className='text-muted mb-0'>{humanReadableSize(resumeSize)}</p>
        )}
      </div>
      <div className='ms-auto'>
        <a
          href={resume_url}
          target='_blank'
          rel='noreferrer'
          download='Resume'
          className='fs-20 text-muted'
        >
          <i className='uil uil-import'></i>
        </a>
      </div>
    </div>
  );
};

export default UploadedFile;
