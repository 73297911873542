import { useSelector } from "react-redux";
import { Col, Input, Label, Row } from "reactstrap";
import SearchableDropdown from "../../../helpers/Common/SearchableDropdown";
import {
  getDropdownOptions,
  getSettingByKey,
  getWorkExpText,
  toArray,
} from "../../../helpers/common";

const BasicInfoForm = ({
  formData,
  setFormData,
  onChangeValue,
  onInputBlur,
}) => {
  const jobLocations = useSelector((state) => state.jobLocations.data) || [];

  const settings = useSelector((state) => state.settings.data);
  const jobDesignationList = toArray(
    getSettingByKey(settings, "job_designations")?.value,
    "\n"
  );

  const workExperienceList = toArray(
    getSettingByKey(settings, "total_work_experiences")?.value,
    "\n"
  );
  // const jobTypeList = toArray(
  //   getSettingByKey(settings, "job_types")?.value,
  //   "\n"
  // );

  return (
    <>
      <div>
        <h5 className='mt-3 mb-2 d-inline-block'>Basic Info</h5>
        <p style={{ fontSize: 14 }}>
          Gathers essential personal and job-related information.
        </p>

        <Row>
          <Col lg={12}>
            <div className='mb-3'>
              <label htmlFor='fullName' className='form-label'>
                Full Name *
              </label>
              <Input
                data-score={3}
                type='text'
                className='form-control'
                id='fullName'
                placeholder='Enter your full name'
                value={formData.name}
                onChange={(e) => onChangeValue(e, "name")}
                onBlur={onInputBlur}
              />
            </div>
          </Col>

          <Col lg={6}>
            <div className='mb-3'>
              <Label htmlFor='email' className='form-label'>
                Email *
              </Label>
              <Input
                data-score={3}
                type='text'
                className='form-control'
                id='email'
                placeholder='Enter your email address'
                value={formData.email}
                onChange={(e) => onChangeValue(e, "email")}
              />
            </div>
          </Col>

          <Col lg={6}>
            <div className='mb-3'>
              <Label htmlFor='mobile' className='form-label'>
                Mobile Number *
              </Label>
              <Input
                data-score={3}
                type='text'
                className='form-control'
                id='mobile'
                placeholder='Enter your mobile number'
                value={formData.mobile_number}
                onChange={(e) => onChangeValue(e, "mobile_number")}
              />
            </div>
          </Col>
        </Row>
      </div>

      <div className='mt-2'>
        <Row>
          <Col lg={6}>
            <div className='mb-3'>
              <label htmlFor='choices-single-location' className='form-label'>
                Your Location *
              </label>
              <SearchableDropdown
                placeholder='Select location'
                options={getDropdownOptions(jobLocations)}
                value={formData.location}
                sortOptionsAlphabetically
                onChange={(value) =>
                  setFormData({ ...formData, location: value?.toString() })
                }
              />
            </div>
          </Col>

          {/* <Col lg={6}>
            <div className='mb-3'>
              <Label htmlFor='current_salary_per_month' className='form-label'>
                Current Salary Per Month *
              </Label>
              <Input
                data-score={3}
                type='number'
                className='form-control'
                id='current_salary_per_month'
                placeholder='Enter current salary per month'
                value={formData.current_salary_per_month}
                onChange={(e) => onChangeValue(e, "current_salary_per_month")}
              />
            </div>
          </Col>

          <Col lg={6}>
            <div className='mb-3'>
              <Label htmlFor='expected_salary_per_month' className='form-label'>
                Expected Salary Per Month
              </Label>
              <Input
                data-score={1}
                type='number'
                className='form-control'
                id='expected_salary_per_month'
                placeholder='Enter expected salary per month'
                value={formData.expected_salary_per_month}
                onChange={(e) => onChangeValue(e, "expected_salary_per_month")}
              />
            </div>
          </Col> */}

          <Col lg={6}>
            <div className='mb-3'>
              <Label htmlFor='total_work_experience' className='form-label'>
                Work Experience *
              </Label>
              <SearchableDropdown
                placeholder='Select work experience'
                options={workExperienceList?.map((exp) => ({
                  label: `${getWorkExpText(exp)}`,
                  value: `${exp}`,
                }))}
                value={formData.total_work_experience}
                onChange={(value) =>
                  setFormData({
                    ...formData,
                    total_work_experience: value?.toString(),
                  })
                }
              />
            </div>
          </Col>

          <Col lg={12}>
            <div className='mb-3'>
              <Label htmlFor='designation' className='form-label'>
                Designation *
              </Label>

              <SearchableDropdown
                placeholder='Select designation'
                options={jobDesignationList}
                value={formData.designation}
                onChange={(value) =>
                  setFormData({ ...formData, designation: value })
                }
              />
            </div>
          </Col>

          {/* <Col lg={6}>
            <div className='mb-3'>
              <Label htmlFor='job_type' className='form-label'>
                Work Type *
              </Label>

              <SearchableDropdown
                placeholder='Select work type'
                options={jobTypeList}
                value={formData.job_type}
                onChange={(value) =>
                  setFormData({ ...formData, job_type: value?.toString() })
                }
              />
            </div>
          </Col> */}
        </Row>
      </div>
    </>
  );
};

export default BasicInfoForm;
