import { createAction } from "redux-actions";
import {
  UPDATE_RESUME_FORM_FAILURE,
  UPDATE_RESUME_FORM_SUCCESS,
} from "../constants/actionTypes";

const updateResumeFormSuccess = createAction(UPDATE_RESUME_FORM_SUCCESS);
const updateResumeFormFailure = createAction(UPDATE_RESUME_FORM_FAILURE);

export function updateResumeForm(data) {
  return (dispatch) => {
    try {
      dispatch(updateResumeFormSuccess({ data }));
    } catch (error) {
      dispatch(updateResumeFormFailure(error));
    }
  };
}
