import React, { useRef } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import "./resume.css";
import { useReactToPrint } from "react-to-print";
import {
  dataUrl,
  getJobLocationById,
  getTimestampInSeconds,
  getWorkExpText,
  toArray,
} from "../../helpers/common";
import { useSelector } from "react-redux";
import {
  getEndData,
  getStartData,
} from "../Account/Candidate/MyProfile/ExperienceJourney";

const Sidebar = ({ formData, jobLocations }) => (
  <div className='sidebar'>
    <img
      src={`${dataUrl}/customers/${formData?.profile_picture}`}
      alt={""}
      className='profile-pic'
    />
    {formData?.name && <h1>{formData?.name}</h1>}
    {formData?.designation && <p>{formData?.designation}</p>}

    <div className='contact-info'>
      <h2>Contacts</h2>

      {formData.mobile_number && (
        <p className='contact-item'>
          <strong>Phone</strong>
          <span> {formData.mobile_number}</span>
        </p>
      )}
      {formData.email && (
        <p className='contact-item'>
          <strong>Email</strong>
          <span>
            <a href={`mailto:${formData.email}`}>{formData.email}</a>
          </span>
        </p>
      )}
      {formData?.location && (
        <p className='contact-item'>
          <strong>Location</strong>
          <span>
            {getJobLocationById(formData?.location, jobLocations)}, India
          </span>
        </p>
      )}
    </div>
    {formData?.skills && (
      <div className='skills'>
        <h2>Skills</h2>
        <ul>
          {toArray(formData?.skills)
            ?.slice(0, 15)
            ?.map((skill, index) => (
              <li key={index}>
                <strong>-</strong> {skill}
              </li>
            ))}
        </ul>
      </div>
    )}
  </div>
);

const Experience = ({ experience, resumeBuilder }) => {
  const totalExperience = resumeBuilder?.total_work_experience
    ? `(${getWorkExpText(resumeBuilder?.total_work_experience)?.trim()})`
    : "";

  if (!experience?.length) {
    return null;
  }
  return (
    <section>
      <h2>Work Experience {totalExperience}</h2>
      {experience?.map((job, index) => (
        <div className='job' key={index}>
          <p className='job-title'>{job?.additional_fields?.position}</p>
          <p className='job-date'>
            {job?.additional_fields?.company_name} - (
            {getStartData(job) + " - " + getEndData(job)})
          </p>

          <p>{job?.additional_fields?.details}</p>
        </div>
      ))}
    </section>
  );
};

const Education = ({ educations }) => {
  if (!educations?.length) {
    return null;
  }
  return (
    <section>
      <h2>Education</h2>

      {educations?.map((education) => (
        <div>
          <p className='mb-0'>
            <strong>{education?.additional_fields?.title}</strong>
          </p>
          <p className='job-date'>
            {education?.additional_fields?.university_college} - (
            {getStartData(education) + " - " + getEndData(education)})
          </p>
          {education?.additional_fields?.certification && (
            <p style={{ color: "#0008" }}>
              <strong>Certification</strong>:{" "}
              {education?.additional_fields?.certification}
            </p>
          )}
        </div>
      ))}
    </section>
  );
};

const ResumePreview = ({ openPreviewModal, setOpenPreviewModal }) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: getTimestampInSeconds() + "- Resume_John_Doe", // Renamed PDF file title
  });

  const resumeBuilder = useSelector((state) => state.resumeBuilder.formData);

  const triggerModal = () => setOpenPreviewModal(!openPreviewModal);

  const jobLocations = useSelector((state) => state.jobLocations.data) || [];

  const { experiences } = useSelector((state) => state.customer);
  const { qualifications } = useSelector((state) => state.customer);

  return (
    <div>
      <Modal
        isOpen={openPreviewModal}
        toggle={triggerModal}
        centered
        size='lg'
        className='resume-modal'
      >
        <ModalBody className='modal-body pt-3 '>
          <div className='resume-container' ref={componentRef}>
            <Sidebar formData={resumeBuilder} jobLocations={jobLocations} />
            <div className='main-content'>
              {resumeBuilder?.details && (
                <section>
                  <h2>Summary</h2>
                  <p>{resumeBuilder?.details}</p>
                </section>
              )}
              <Experience
                experience={experiences}
                resumeBuilder={resumeBuilder}
              />
              <Education educations={qualifications} />
            </div>
          </div>

          <div className='d-flex justify-content-end gap-2'>
            <Button className='mt-4' onClick={triggerModal}>
              Cancel
            </Button>

            <Button className='mt-4' color='primary' onClick={handlePrint}>
              Download
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ResumePreview;
