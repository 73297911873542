import React, { useRef } from "react";
import { useState } from "react";
import { Button, Spinner } from "reactstrap";
import { postAttachment } from "../../../../api/attachment";
import { CustomSnackbar } from "../../../../helpers/CustomSnackbar";
import ResumeImg from "../../../../assets/images/dummy-resume.png";
import UploadedFile from "./UploadedFile";
import { useNavigate } from "react-router-dom";

const DocAttachments = ({ setFormData }) => {
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  console.log("uploadedFile::", uploadedFile);
  const navigate = useNavigate();

  const fileInputRef = useRef(null);

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleDocUpload = (e, fieldName) => {
    const file = e?.target?.files[0];
    setUploadedFile(file);
    const allowedExt = [
      "jpg",
      "jpeg",
      "png",
      "pdf",
      "docx",
      "doc",
      "zip",
      "ppt",
      "pptx",
      "txt",
    ];

    if (file?.name) {
      const ext = file.name.split(".").pop();

      if (!allowedExt.includes(ext)) {
        CustomSnackbar.error(
          `Error! Only ${allowedExt.join(", ")} file types are allowed.`
        );
        return;
      }
    }

    if (file?.size > 3000000) {
      CustomSnackbar.error("Error! File size must be less than 3 MB.");
      return;
    }

    const formData = new FormData();
    formData.append("attachment", file);

    if (file) {
      setLoading(true);

      postAttachment("/customers/attachment", formData)
        .then((res) => {
          let data = res?.data;

          if (data) {
            let fileResponse = JSON.parse(res?.data);
            let file = fileResponse?.files[0];

            setFormData((formData) => ({
              ...formData,
              [fieldName]: file?.name,
              [fieldName + "_size"]: file?.size,
            }));
          }
        })
        .catch((err) => {
          console.log("err::", err);

          if (err?.response?.data?.message) {
            CustomSnackbar.error(err?.response?.data?.message);
          } else {
            CustomSnackbar.error(err?.message);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <div className='cust-divider'></div>
      <h5 className='fs-17 fw-semibold mb-3 mt-4'>Resume</h5>
      {loading && (
        <div style={{ textAlign: "center", padding: "30px 0" }}>
          <Spinner />
        </div>
      )}

      <div className='resume-builder-section'>
        <div className='dummy-resume'>
          <img src={ResumeImg} alt='' />
        </div>
        <div>
          <h6>Create and Customize Your Professional Resume</h6>

          <p className='mb-1' style={{ fontSize: 13 }}>
            Effortlessly build a professional resume with our Resume Builder.
            {/* Import your profile details, tweak them as needed, and download your
            customized resume in minutes. */}
          </p>
        </div>

        <Button
          color='primary'
          outline
          size='sm'
          type='button'
          onClick={() => navigate("/resume-builder")}
        >
          Create resume
        </Button>
      </div>

      {/* Resume uploader */}
      {uploadedFile && (
        <div className='mt-5'>
          <UploadedFile
            name={uploadedFile?.name}
            resumeSize={uploadedFile?.size}
          />
        </div>
      )}

      <div className='resume-uploader' onClick={handleDivClick}>
        <h6>
          Already have a resume? <span>Upload resume</span>
        </h6>

        <p className='mb-1' style={{ fontSize: 13 }}>
          (<span>Allowed File types are:</span> jpg, jpeg, png, pdf, doc, zip,
          docx, ppt, pptx, txt)
        </p>

        <input
          type='file'
          ref={fileInputRef}
          style={{ display: "none" }}
          multiple
          accept='.jpg,.jpeg,.png,.pdf,.doc,.zip,.docx,.ppt,.pptx,.txt'
          onChange={(e) => handleDocUpload(e, "resume")}
        />
      </div>
      {/* <Row style={{ display: loading ? "none" : "flex" }}>
        <Col lg={6}>
          <div className="mb-3">
            <Label htmlFor="attachmentscv" className="form-label">
              Resume{" "}
            </Label>
            <input
              className="form-control"
              type="file"
              id="attachmentscv"
              onChange={(e) => handlePicUpload(e, "resume")}
            />
          </div>
        </Col>

        <Col lg={6}>
          <div className="mb-3">
            <Label htmlFor="attachmentscv" className="form-label">
              Cover Letter
            </Label>
            <Input
              className="form-control"
              type="file"
              id="attachmentscv"
              onChange={(e) => handlePicUpload(e, "cover_letter")}
            />
          </div>
        </Col>

        <Col>
          <p className="mb-1" style={{ fontSize: 13 }}>
            (<span>Allowed File types are:</span> jpg, jpeg, png, pdf, doc, zip,
            docx, ppt, pptx, txt)
          </p>
        </Col>
      </Row> */}
    </>
  );
};

export default DocAttachments;
