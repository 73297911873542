import { combineReducers } from "redux";
import menus from "./menus";
import categories from "./categories";
import banners from "./banners";
import contents from "./contents";
import settings from "./settings";
import meta from "./meta";
import assets from "./assets";
import jobs from "./jobs";
import clientTestimonials from "./clientTestimonials";
import customer from "./customer";
import faqs from "./faqs";
import employer from "./employer";
import bookmarkJob from "./bookmarkJob";
import bookmarkCandidates from "./bookmarkCandidates";
import jobLocations from "./jobLocations";
import companies from "./companies";
import candidate from "./candidate";
import notifications from "./notifications";
import orders from "./orders";
import resumeBuilder from "./resumeBuilder";

const appReducers = combineReducers({
  menus,
  categories,
  banners,
  contents,
  settings,
  meta,
  assets,
  jobs,
  customer,
  clientTestimonials,
  faqs,
  employer,
  candidate,
  bookmarkJob,
  bookmarkCandidates,
  jobLocations,
  companies,
  notifications,
  orders,
  resumeBuilder,
});

export default appReducers;
