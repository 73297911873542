import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import { Icon } from "@iconify/react";
import { toArray } from "../../../helpers/common";

const SkillsForm = ({ formData, setFormData }) => {
  const [skillInput, setSkillInput] = useState("");
  const [skills, setSkills] = useState([]);

  const maxAllowedSkills = 15;

  const isLimitReached = skills?.length >= maxAllowedSkills;

  useEffect(() => {
    if (formData?.skills) {
      setSkills(toArray(formData?.skills)?.slice(0, maxAllowedSkills));
    }
  }, [formData?.skills]);

  const handleInputChange = (event) => {
    setSkillInput(event.target.value);
  };

  const handleOnBlur = () => {
    addSkills(skillInput);
  };

  const handleKeyPress = (event) => {
    if (event.key === "," || event.key === "Enter") {
      event.preventDefault();
      addSkills(skillInput);
    }
  };

  const addSkills = (input) => {
    const trimmedInput = input.trim();
    if (trimmedInput) {
      const newSkills = trimmedInput.split(",").map((skill) => skill.trim());
      const uniqueNewSkills = newSkills.filter(
        (skill) => !skills.includes(skill)
      );

      const updatedSkills = [...skills, ...uniqueNewSkills]?.slice(
        0,
        maxAllowedSkills
      );

      setSkills(updatedSkills);
      setSkillInput("");

      setFormData({ ...formData, skills: updatedSkills?.join(", ") });
    }
  };

  const removeSkill = (index) => {
    const updatedSkills = skills?.filter((_, i) => i !== index);
    setSkills(updatedSkills);
    setFormData({ ...formData, skills: updatedSkills?.join(", ") });
  };

  return (
    <div className='mb-4'>
      <h5 className='mt-3 mb-2 d-inline-block'>Skills</h5>
      <p style={{ fontSize: 14 }}>
        Add your skills below, separating each skill with a comma for clarity.
      </p>

      <div className='skills-input'>
        {skills?.length > 0 && (
          <div className='pill-container'>
            {skills?.map((skill, index) => (
              <span key={index} className='pill-btn'>
                {skill}

                <div className='icon' onClick={() => removeSkill(index)}>
                  <Icon icon={"charm:cross"} color='#606060' fontSize={14} />
                </div>
              </span>
            ))}
          </div>
        )}

        <Input
          type='text'
          className='form-control'
          placeholder='Eg. JavaScript, React, Node.js'
          value={skillInput}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          onBlur={handleOnBlur}
          readOnly={isLimitReached}
        />
      </div>

      <p style={{ fontSize: 12, color: "#606060", marginTop: -8 }}>
        Note: You can add up to {maxAllowedSkills} skills.
      </p>
    </div>
  );
};

export default SkillsForm;
